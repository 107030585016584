<template>
	<div class="mdc-snackbar">
		<div class="mdc-snackbar__surface">
			<div class="mdc-snackbar__label" aria-atomic="false" v-html="options.message"></div>
			<div class="mdc-snackbar__actions" aria-atomic="true">
				<button type="button" class="mdc-button mdc-snackbar__action" @click="options.action" v-if="options.action && options.actionLabel">
					<div class="mdc-button__ripple"></div>
					<span class="mdc-button__label" v-html="options.actionLabel"></span>
				</button>
				<button class="mdc-icon-button mdc-snackbar__action" title="Dismiss" @click="close" v-if="options.dismissable"><ion-icon name="close"></ion-icon></button>
			</div>
		</div>
	</div>
</template>

<script>
// Dependencies
import { MDCSnackbar } from '@material/snackbar'

// Component
export default {
	name: 'noticePlugin',
	data: () => ({
		snackbar: null,
		defaults: {
			actionButtonText: '',
			action: () => ({}),
			timeout: 5000,
			countdown: undefined,
			dismissable: true,
		},
		options: {},
		queued: [],
		timer: null,
		interval: null,
	}),
	mounted() {
		this.snackbar = new MDCSnackbar(this.$el)
		this.snackbar.listen(`MDCSnackbar:closed`, () => {
			this.queued.shift()
		})

	},
	methods: {
		close() {
			this.snackbar.close()
		},
	},
	watch: {
		queued(opt) {
			if (!opt.length) return

			const options = this.queued[0]
			this.options = Object.assign({}, this.defaults, options)
			this.snackbar.timeoutMs = this.options.timeout
			this.snackbar.open()

			if (this.options.countdown) {
				let c = this.options.countdown
				let label = this.options.actionLabel
				console.log({ label, options: this.options, })
				this.options.actionLabel = `${label} (${c})`
				this.interval = setInterval(() => {
					c--
					if (c == 0) {
						this.interval = clearInterval(this.interval)
						this.snackbar.close()
						this.options.action()
					}
					this.options.actionLabel = `${label} (${c})`
				}, 1000)
			}
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/snackbar/mdc-snackbar';
@use '@material/button';
@use "@material/icon-button/mdc-icon-button";

.mdc-snackbar {

	&__action {
		@include button.ink-color(modules.color_('secondary', 'light'));
		color: white;
	}

	ion-icon {
		color: modules.color_('text', 'light');
	}
}
</style>
