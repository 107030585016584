
import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'

const DEFAULTS = {
	opportunity: {
	},
	opportunities: [],
	presentation: {},
	presentations: [],
	events: [],
}
const state = () => ({
	...DEFAULTS,
})

const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let opportunities
		try {
			opportunities = await API.get(`/opportunities`, params)
			commit(types.SET_OPPORTUNITIES, opportunities)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return opportunities
	},
	async get({ commit }, opportunityId) {
		let opportunity
		try {
			opportunity = await API.get(`/opportunities/${opportunityId}`)
			commit(types.SET_OPPORTUNITY, opportunity)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return opportunity
	},
	async create({ commit }, data) {
		let opportunity
		try {
			opportunity = await API.send(`/opportunities`, data)
			commit(types.SET_OPPORTUNITY, opportunity)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return opportunity
	},
	async delete({ commit }, opportunityId) {
		this.dispatch('misc/setLoading', true)
		let opportunity
		try {
			opportunity = await API.remove(`/opportunities/${opportunityId}`)
			this.dispatch('misc/setLoading', false)
			commit(types.REMOVE_OPPORTUNITY, opportunity)
			// commit(types.SET_OPPORTUNITY, opportunity)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}
	},
	async update({ commit }, { opportunityId, data }) {
		this.dispatch('misc/setLoading', true)
		let opportunity
		try {
			opportunity = await API.update(`/opportunities/${opportunityId}`, data)
			commit(types.SET_OPPORTUNITY, opportunity)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}

		return opportunity
	},
	async upsert({ commit }, opportunity) {
		commit(types.UPSERT_OPPORTUNITY, opportunity)
	},
	async addPricing({ commit }, { opportunity }) {
		opportunity.pricingOptions = opportunity.pricingOptions || []
		opportunity.pricingOptions = Array.isArray(opportunity.pricingOptions) ? opportunity.pricingOptions : Object.values(opportunity.pricingOptions)
		opportunity.pricingOptions.push({
			loanType: 'Taxable',
			dateStart: 0,
			amortizationType: 'Mortgage',
		})

		commit(types.SET_OPPORTUNITY, opportunity)
	},
	async createPresentation({ commit }, { opportunityId, data }) {
		this.dispatch('misc/setLoading', true)
		let presentation
		try {
			presentation = await API.send(`/opportunities/${opportunityId}/presentation`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_PRESENTATION, presentation)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error )
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return presentation
	},
	async listPresentations({ commit }, { opportunityId, params }) {
		let presentations
		try {
			presentations = await API.get(`/opportunities/${opportunityId}/presentations`, params)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_PRESENTATIONS, presentations)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return presentations
	},
	async deletePresentation({ commit }, { opportunityId, presentationId }) {
		let presentation
		try {
			presentation = await API.remove(`/opportunities/${opportunityId}/presentations/${presentationId}`)
			commit(types.REMOVE_PRESENTATION, presentation)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}
		return presentation
	},
	async refreshPresentation({ commit }, { opportunityId, presentationId }) {
		let presentation
		try {
			presentation = await API.update(`/opportunities/${opportunityId}/presentations/${presentationId}`, {})
			commit(types.SET_PRESENTATION, presentation)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}
		return presentation
	},
	async getPresentation({ commit }, { opportunityId, presentationId }) {
		let presentation
		try {
			presentation = await API.get(`/opportunities/${opportunityId}/presentations/${presentationId}`, {}, {}, false)
			commit(types.SET_PRESENTATION, presentation)
		} catch (error) {
			console.log('catch', error)
			// this.dispatch('misc/setError', error.message || error)
			// this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}
		return presentation
	},
	async sendPresentation({ commit }, { opportunityId, presentationId, to, message}) {
		try {
			await API.send(`/opportunities/${opportunityId}/presentation/${presentationId}/send`, {to, message})
		} catch (error) {
			throw new Error(error.message || error)
		}
	},
	async refreshPresentation({ commit }, { opportunityId, presentationId }) {
		let presentation
		try {
			presentation = await API.send(`/opportunities/${opportunityId}/presentation/${presentationId}/calculate`, null, false)
			commit(types.SET_PRESENTATION, presentation)
		} catch (error) {
			throw new Error(error.message || error)
		}

		return presentation
	},
	async listActivity({ commit }, { opportunityId, params }) {
		let events
		try {
			events = await API.get(`/opportunities/${opportunityId}/activity`, params)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_EVENTS, events)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return events
	},
	async listAllActivity({ commit }, { params }) {
		let events
		try {
			events = await API.get(`/opportunities/activity`, params)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_EVENTS, events)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return events
	},
	async sendAnalysis({ commit }, { opportunity, idx }) {
		this.dispatch('misc/setLoading', true)

		try {
			await API.send(`/opportunities/${opportunity.opportunityId}/analysis/${idx}`)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}
	},
	async deletePricing({ commit }, { opportunity, idx }) {
		opportunity.pricingOptions.splice(idx, 1)
		opportunity = await API.update(`/opportunities/${opportunity.opportunityId}`, opportunity)
		commit(types.SET_OPPORTUNITY, opportunity)
	},
	async unset({ commit }) {
		commit(types.UNSET_OPPORTUNITY)
		commit(types.UNSET_PRESENTATION)
	}
}

const mutations = {
	[types.SET_OPPORTUNITIES](state, opportunities) {
		state.opportunities = opportunities.map(o => {
			return o
		})
	},
	[types.SET_OPPORTUNITY](state, opportunity) {
		state.opportunity = opportunity
		Vue.set(state, 'opportunity', opportunity)

		const idx = state.opportunities.findIndex(o => o.opportunityId == opportunity.opportunityId)
		if (idx > -1) {
			state.opportunities[idx] = opportunity
		} else {
			state.opportunities.push(opportunity)
		}
		Vue.set(state, 'opportunities', [...state.opportunities])
	},
	[types.REMOVE_OPPORTUNITY](state, opportunity) {
		state.opportunity = {}
		const idx = state.opportunities.findIndex(p => p.opportunityId == opportunity.opportunityId)
		if (idx > -1) {
			state.opportunities.splice(idx, 1)
		}

		Vue.set(state, 'opportunities', [...state.opportunities])
	},
	[types.SET_PRESENTATIONS](state, presentations) {
		state.presentations = presentations.map(p => {
			return p
		})
	},
	[types.SET_PRESENTATION](state, presentation) {
		state.presentation = presentation

		const idx = state.presentations.findIndex(p => p.presentationId == presentation.presentationId)
		if (idx > -1) {
			state.presentations[idx] = presentation
		} else {
			state.presentations.push(presentation)
		}

		Vue.set(state, 'presentations', [...state.presentations])
	},
	[types.REMOVE_PRESENTATION](state, presentation) {
		state.presentation = {}
		const idx = state.presentations.findIndex(p => p.presentationId == presentation.presentationId)
		if (idx > -1) {
			state.presentations.splice(idx,1)
		}

		Vue.set(state, 'presentations', [...state.presentations])
	},
	[types.SET_EVENTS](state, events) {
		state.events = events

		Vue.set(state, 'events', [...events])
	},
	[types.UNSET_OPPORTUNITY](state) {
		state.opportunity = DEFAULTS.opportunity
	},
	[types.UNSET_OPPORTUNITIES](state) {
		state.opportunities = DEFAULTS.opportunities
	},
	[types.UNSET_PRESENTATION](state) {
		state.presentation = {...DEFAULTS.presentation}
	},
	[types.UNSET_PRESENTATIONS](state) {
		state.presentations = [...DEFAULTS.presentations]
	},
	[types.UNSET_EVENTS](state) {
		state.events = [...DEFAULTS.events]
	},
	[types.UPSERT_OPPORTUNITY](state, opportunity) {
		state.opportunity = {...state.opportunity, ...opportunity}
	}
}

const getters = {
	opportunities: state => state.opportunities.map(o => {
		o.org = o.organization?.name
		return o
	}),
	opportunity: state => {
		if (state.opportunity.pricingOptions) {
			state.opportunity.pricingOptions = Array.isArray(state.opportunity.pricingOptions) ? state.opportunity.pricingOptions : Object.values(state.opportunity.pricingOptions)
		}
		return state.opportunity
	},
	presentation: state => state.presentation,
	presentations: state => state.presentations,
	events: state => state.events,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
